import { get, put,Delete,post } from '../axios'

//平台端-供应商列表
export const goodSupplierList = params => get(`/user/web/supplier/list`,params);
//平台端-供应商管理-新增
export const goodSupplierAdd = params => post(`/user/web/supplier/add`,params);
//平台端-供应商管理-新增
export const updatePassword = params => put(`/user/web/supplier/updatePassword/${params}`);
//平台端-供应商管理-是否启用-修改
export const supplierStateUpdate = params => put(`/user/web/supplier/state/update`,params);
//平台端-供应商管理-详情
export const supplierDetail = params => get(`/user/web/supplier/detail/${params}`);
//供应商列表不分页-下拉框使用
export const getSupplierListAll = params => get(`/user/web/supplier/supplierList`,params);
