<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="100px"
      >
        <div>
          <el-form-item label="ID：" prop="id">
            <el-input
              placeholder="请输入ID"
              v-model="tableFrom.id"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="供应商名称：" prop="name">
            <el-input
              placeholder="请输入供应商名称"
              v-model="tableFrom.name"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人：" prop="contact">
            <el-input
              placeholder="请输入联系人"
              v-model="tableFrom.contact"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="mobile">
            <el-input
              placeholder="请输入手机号码"
              v-model="tableFrom.mobile"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="账号：" prop="username">
            <el-input
              placeholder="请输入账号"
              v-model="tableFrom.username"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="商品数：" prop="goodsCount">
            <el-input
              placeholder="请输入商品数"
              v-model="tableFrom.goodsCount"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="状态：" prop="state">
            <el-select
              v-model="tableFrom.state"
              placeholder="请选择"
              class="selWidth"
              clearable
            >
              <el-option value="1" label="启动" />
              <el-option value="2" label="禁用" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间：" prop="time">
            <el-date-picker
              v-model="tableFrom.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="search()"
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-card class="box-card">
      <div class="mb20 acea-row">
        <el-button size="small" type="primary" class="mb10" @click="onAdd"
          >添加供应商</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="id"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
       <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        >
        </el-table-column>
        <el-table-column label="供应商名称" prop="name" min-width="80"></el-table-column>
        <el-table-column label="联系人" prop="contact" min-width="80"></el-table-column>
        <el-table-column label="联系人电话" prop="mobile" min-width="80"> </el-table-column>
        <el-table-column label="账号" prop="username" min-width="80"> </el-table-column>
        <el-table-column label="添加时间" prop="createTime" min-width="120"> </el-table-column>
        <el-table-column prop="status" label="是否显示" min-width="150">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
              active-text="显示"
              inactive-text="隐藏"
              @change="onchangeIsShow(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="170" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="resetPassword(scope.row.id)"
               icon="el-icon-s-tools"
              >重置密码</el-button
            >
            <el-button
              type="text"
              size="small"
               icon="el-icon-info"
              @click="onDetails(scope.row.id)"
              >详情</el-button
            >
          </template>
        </el-table-column>
    
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="500"
      :before-close="handleClose"
    >
      <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
      <template slot="logo">
          <FormImgUpload
            :url="form.logo"
            @upload="logoUpload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div  class="tipImg">尺寸：300x300</div>
          <div  class="tipImg">格式：jpeg,png,jpg</div>
        </template>
      <template slot="authorization">
          <FormImgUpload
            :url="form.authorization"
            @upload="authorizationUpload"
            :limit="3"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div  class="tipImg">格式：jpeg,png,jpg</div>
        </template>
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="3"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div  class="tipImg">格式：jpeg,png,jpg</div>
        </template>
        
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <SupplierDetails
      ref="supplierDetails"
      @closeDrawer="closeDrawer"
      :drawer="drawer"
    ></SupplierDetails>
  </div>
</template>

<script>
import {
  goodSupplierList,
  goodSupplierAdd,
  updatePassword,
  supplierStateUpdate
} from "@/api/supplier/supplier";
import {getIndex} from "@/utils/other";
import { enterNumberEnglish } from "@/utils/formRules";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import SupplierDetails from "./components/supplierDetails.vue";
import moment from 'moment';
import { mobilePhoneRule } from "@/utils/formRules";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
    SupplierDetails
  },
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      title: "新增供应商",
      dialogVisible: false,
      drawer:false,
      form: {
        image: [],
        logo:[],
        authorization:[]
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        id:'',
        name:'',
        contact:'',
        mobile:'',
        username:'',
        goodsCount:'',
        state:'',
        time:[],
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "联系人",
            prop: "contact",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入联系人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "联系人电话",
            prop: "mobile",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入联系人电话",
                trigger: "blur",
              },
              ...mobilePhoneRule
            ],
          },
          {
            label: "供应商名称",
            prop: "name",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入供应商名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "账号",
            prop: "username",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入账号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "密码",
            prop: "password",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入密码",
                trigger: "blur",
              },
              ...enterNumberEnglish
            ],
          },
          {
            label: "logo",
            row: true,
            type: "text",
            prop: "logo",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传logo",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "授权书",
            row: true,
            type: "text",
            prop: "authorization",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传授权书",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "资质",
            row: true,
            type: "text",
            prop: "image",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传资质",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "状态",
            prop: "state",
            type: "switch",
            row: true,
            dicData: [
              {
                label: "禁用",
                value: 2,
              },
              {
                label: "启用",
                value: 1,
              },
            ],
            value:1
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    search(){
     this.tableFrom.pageNum=1
     this.getList()
    },
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    formSubmit() {},
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;
          console.log('1231',this.form)
            goodSupplierAdd({...that.form,logo:this.form.logo[0],qualifications:that.form.image.length>0?that.form.image.toString():'',authorization:that.form.authorization.length>0?that.form.authorization.toString():''})
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  that.$message.success(res.msg);
                  that.getList()
                  that.handleClose()
                  // done();
                } else {
                  that.$message.error(res.msg);
                  // done();
                }
              })
          // done()
        }
      });
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time= []
      this.getList();
    },
    closeDrawer() {
      this.drawer = false;
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.form.image=[]
      this.form.authorization=[]
      this.form.isShow=0
      this.form.state=1
      this.dialogVisible = false;
    },
    // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    logoUpload(e){
      if (Array.isArray(e)) {
        this.form.logo = [...e];
      } else {
        this.form.logo = [...this.form.logo, e];
      }
    },
    authorizationUpload(e){
      if (Array.isArray(e)) {
        this.form.authorization = [...e];
      } else {
        this.form.authorization = [...this.form.authorization, e];
      }
    },
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time&&this.tableFrom.time.length>0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format('YYYY-MM-DD HH:mm:ss');
        this.tableFrom.endTime = moment(this.tableFrom.time[1]).format('YYYY-MM-DD')+' 23:59:59';
      }else{
        this.tableFrom.startTime=''
        this.tableFrom.endTime=''
        
      }
      goodSupplierList({...this.tableFrom,time:''})
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.tableFrom.pageNum = val
      this.getList()
    },
    // 添加
    onAdd() {
      this.form.id=-null
      
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      console.log('1231',this.form)
      this.form.image = [this.form.logo]
      this.form.parentId =[this.form.parentId]
      console.log('1231',this.form)
      this.dialogVisible = true
    },
    //供应商详情
    onDetails(id) {
      this.drawer = true;
      this.$refs.supplierDetails.getInfo(id);
    },
    // 重置密码
    resetPassword(id) {
      let that = this;
      this.$confirm(`确认重置密码`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            updatePassword(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    onchangeIsShow(row) {
      supplierStateUpdate({id:row.id,state:row.state})
        .then(({ msg }) => {
          this.getList()
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList()
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
